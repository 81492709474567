export enum NotificationActivityType {
    AccountCreation,
    ForgotPassword,
    ChangePassword,
    ReferralSubmitted,
    LoanMilestonePreApproved,
    LoanMilestoneSubmittedToUnderwriting,
    LoanMilestoneClearedToClose,
    LoanMilestoneFunded,
    RequestConsent,
    LeadReferral,
    ClientAssigned,
    LoanOfficerAssigned,
    SendClientApplication,
    SendClientApplicationWithUnknowLoanOfficer,
    BuyerAcceptedProgram,
    OutstandingLoanConditions,
    REASpecialistTeamBAP,
    PALDownload,
    SalesTeamNotificationOnREARegistration,
    ClientReferralNoLoanOfficer,
    LoanMilestonePreQualified,
    PreQualDownload,
    LeadReferralUnknownLoanOfficer,
    HoaInfoSubmitted,
    AppraisalValueCleared,
    AgentRelationshipManagerSignUp,
    AgentRelationshipManagerReferral,
    LoNoteToReaShare,
    ReaNoteToLoShare,
    MarketingFlyerDownload,
    RequestNewPal,
    DealAssignedToWrongPartner,
    ClientAssignedREA,
    SignUpNewREA,
    ProfileValidated,
    PALSentByREA,
    ReferralReminder,
    LoanMilestoneSetupREAInvite,
    ProductAnnouncements,
}

export enum NotificationType {
    Email = 1,
    Sms = 2,
    Push = 3,
}

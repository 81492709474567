export enum UserRoleListType {
    RealEstateAgents = 'Real Estate Agents',
    Builders = 'Builders',
    // All preferred Lo's for active reas
    LoanOfficers = 'Loan officers',
    // All REA's, no LO's
    All = 'All NAF Connect Users',
}

export enum UserRoleListTypeDefault {
    RealEstateAgents,
    Builders,
    // All preferred Lo's for active reas
    LoanOfficers,
    // All REA's, no LO's
    All,
}

import { CustomDropDownOption } from '../components';
import { UserRoleListType } from '../enums';

export const userRoleListEnumToDropdownArray = (
    enumObj: typeof UserRoleListType
): Array<CustomDropDownOption> =>
    Object.values(enumObj).map((label, index) => ({
        value: String(index),
        label,
    }));
